<script lang="ts" setup>
import type { ContentTypePage } from '~~/types/storyblok/contentTypes'
import type { BlokHero } from '~~/types/storyblok/bloks'

const props = defineProps<{
  blok: ContentTypePage
  title: string
}>()

const items = computed(() => {
  if (!props.blok?.body) return []
  if (!mainHeader.value) return props.blok.body
  return props.blok.body.filter((element, index) => {
    if (index) {
      return element
    }
  })
})

const mainHeader = computed(() => {
  return props.blok?.body?.[0].component === 'BlokHero'
    ? (props.blok.body[0] as BlokHero)
    : undefined
})

// SEO settings
const pageTitle = props.blok?.seo?.title || props.title || ''
const pageDescription = props.blok?.seo?.description || ''
const pageImage = props.blok?.seo_image?.filename || ''

const { applyMeta } = usePageMetaHelper(useRoute())

applyMeta({
  name: props.title,
  title: pageTitle,
  description: pageDescription,
  image: pageImage,
})
</script>

<template lang="pug">
article(v-editable="blok" class="content-page")
  StoryblokComponent(
    v-if="mainHeader"
    :blok="mainHeader"
    isMain
  )
  StoryblokComponent(
    v-for="item in items"
    :key="item._uid"
    :blok="item"
  )
</template>
